var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingTop: _vm.calculatedHeight + 'px'
  })},[_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showReplyInputs || _vm.focused || _vm.loading),expression:"showReplyInputs || focused || loading"}],ref:"comment-reply",staticClass:"comment-reply"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true,"height":2,"color":_vm.theme_color}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.replyName),expression:"replyName"}],ref:"reply-name-input",staticClass:"comment-reply__name-input",attrs:{"type":"text","placeholder":_vm.$translate('way_comments_name-input-placeholder'),"disabled":_vm.loading},domProps:{"value":(_vm.replyName)},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"input":function($event){if($event.target.composing){ return; }_vm.replyName=$event.target.value}}}),_c('div',{staticClass:"comment-reply__content"},[(_vm.reply_name && _vm.reply_id)?_c('span',{ref:"name-area",staticClass:"comment-reply__content__name",style:({
            top: -_vm.textAreaScrollTop + 'px',
          })},[_vm._v(" "+_vm._s('@'+_vm.reply_name)+" ")]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.replyContent),expression:"replyContent"}],ref:"reply-input",staticClass:"comment-reply__content-input",style:({
            textIndent: _vm.textAreaOffset + 'px',
          }),attrs:{"disabled":_vm.loading,"placeholder":_vm.$translate(_vm.reply_name 
              ? 'way_comments_reply-input-placeholder' 
              : 'way_comments_comment-input-placeholder'
            ),"name":"text","id":"","rows":"2"},domProps:{"value":(_vm.replyContent)},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"scroll":_vm.handleContentScroll,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.handleContentDelete($event)},"input":function($event){if($event.target.composing){ return; }_vm.replyContent=$event.target.value}}})]),_c('v-slide-y-reverse-transition',[(_vm.replyName && _vm.replyContent)?_c('div',{staticClass:"comment-reply__submit",on:{"click":_vm.postComment}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":20,"width":2,"indeterminate":"","color":"#FFF"}}):_c('p',{staticClass:"small-medium"},[_vm._v(_vm._s(_vm.$translate('way_comments_comment-submit-btn')))])],1):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }