<template>
  <div>
    <video 
      v-if="video_src"
      controls
      class="way-article-video"
    >
      <source 
        :src="video_src" 
        :type="video_content_type"
      />
    </video>
    <div v-else-if="video_loading">
      <p>{{ $translate('way_article-video_loading') }}</p>
    </div>
    <div 
      v-else-if="video_error"
    >
      <p>{{ $translate('way_article-video_error-title') }}</p>
      <p style="color: red;">{{ video_error }}</p>
      <v-btn 
        type="submit"
        @click="getVideo"
      >
        {{ $translate('way_article-video_retry-btn') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import wayApi from '@/helpers/wayApi';
export default {
  name: 'ArticleVideo',
  props: ['link'],
  data(){
    return {
      video_content_type: undefined,
      video_error: undefined,
      video_loading: true,
      video_src: undefined,
    }
  },
  mounted(){
    this.getVideo()
  },
  methods: {
    async getVideo(){
      this.video_loading=true;
      fetch(process.env.VUE_APP_ERGWAY_HOST+encodeURI(this.link),{
        headers: {
          Authorization: wayApi.defaults.headers['Authorization']
        },
        // duplex: 'half',
      }).then((response) => {
        if (response.ok){
          console.log('video response:', response);
          this.video_content_type = response.headers.get('content-type') 
            || response.headers.get('Content-Type');
          return response.blob();          
        } else {
          throw this.$translate('way_article-video_download-error');
        }
      })
      .then(blob => URL.createObjectURL(blob))
      // Update image
      .then(url => this.video_src = url)
      .catch((err) => {
        this.video_error = err;
        throw err;
      }).finally(() => {
        this.video_loading = false;
      })
      
    },
  }
}
</script>
<style lang="scss">
.way-article-video{
  width: 100%;
  height: auto;
  margin: 0 0 12px;
}
</style>