<template>
  <div 
    class="way-article-content"
    ref="content"
    v-html="content"
  ></div>
</template>

<script>
import wayApi from '@/helpers/wayApi';
export default {
  name: 'ArticleContent',
  props: ['content'],
  mounted(){
    // КОСТЫЛЬ
    this.$nextTick(() => {
      this.$refs.content.getElementsByTagName('img').forEach((imgEl) => {
        this.getAndReplaceContentImages(imgEl);
      })
    })
  },
  methods: {
    getAndReplaceContentImages(el){
      fetch(encodeURI(el.src).replace('way.erg.kz', process.env.VUE_APP_ERGWAY_IMG_DOMAIN),{
        headers: {
          Authorization: wayApi.defaults.headers['Authorization']
        }
      }).then(r => 
        r.blob()
      ).then( d => {
        el.src = window.URL.createObjectURL(d);
        el.style.width = '100%';
        el.style.height = 'auto';
      });
    },
  },
}
</script>
<style lang="scss">
.way-article-content{
  overflow: hidden;
  ol, ul, li {
    margin: revert;
    padding: revert; /* Padding is what gives the indentation */ 
  } 
  ol, ul {
    padding-inline-start: 16px;
  }
}
</style>