<template>
  <div class="event-full-card">
    <div @click="$emit('click')">
      <v-img 
        aspect-ratio="1.9"
        :src="src"
        :style="{
          borderRadius: '2px',
        }"
      />
      <div class="event-full-card__info-top">
        <div 
          v-if="location"
          class="d-flex align-items-center"
        >
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-8'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.5.81l-15 5.65 7.075 2.448 2.449 6.902L16.5.81z" stroke="#667A8B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <span class="small text-gray">{{ location }}</span>
        </div>
        <!-- <FullCardRating 
          class="ml-auto"
          v-if="rating >= 0"
          :value="rating"
        /> -->
      </div>
      <p class="large-medium">{{ title || $translate('way_full-card_no-title') }}</p>
    </div>
    <!-- <ArticleInfo 
      class="mt-4"
      :commentsLength="commentsLength"
      :date="date"
      :showShare="false"
      :views="views"
      @shareClick="handleShareClick"
    /> -->
  </div>
</template>

<script>
// import FullCardRating from "@/components/Way/partials/FullCardRating"
// import ArticleInfo from './ArticleInfo.vue';
import myApi from "@/helpers/myApi";
import bridge from '@/helpers/bridge';

export default {
  name: 'WayFullCard',
  components: {
    // FullCardRating,
    // ArticleInfo,
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
    rating: {
      type: Number,
    },
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    views: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
    },
    commentsLength: {
      type: Number,
      default: 0,
    },
    location: {
      type: String,
    },
    sharePath: {
      type: String,
    }
  },
  data(){
    return {
      src: undefined,
    }
  },
  methods: {
    getImage(){
      if (this.image);
      console.log(this.image);
      // let path = this.image;
      let url = process.env.VUE_APP_MY_HOST + this.image;
      fetch(url, {
        headers: {
          Authorization: myApi.defaults.headers['Authorization']
        }
      }).then(r => 
        r.blob()
      ).then( d => 
        this.src=window.URL.createObjectURL(d)
      );
    },

    handleShareClick(){
      bridge.share('https://smarterg.app.link'
        +'?command=apps'
        +'&id=7ef949af-9058-11eb-bbd2-768a8a250dd0'
        +'&ergID=7ef949af-9058-11eb-bbd2-768a8a250dd0'
        +`&to_url=${encodeURI(this.sharePath)}`
      )
    },

  },
  mounted(){
    this.getImage();
  },
}
</script>

<style lang="scss">
.event-full-card{
  display: block;
  margin: 0 0 32px;
  &__info-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 4px;
  }
  
}
</style>