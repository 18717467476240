<template>
  <div 
    class="way-article-users"
  >
    <div 
      v-for="(user, k) in users"
      :key="k"
      class="way-article-users__user"
    >
      <div class="way-article-users__user-avatar">
        <img :src="userImgList[user.id]" alt="">
      </div>
      <div class="way-article-users__user-info">
        <p class="small-medium">{{ user.firstname + ' ' + user.surname }}</p>
        <p class="small text-gray">{{ user.position }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import wayApi from '@/helpers/wayApi';
export default {
  name: 'ArticleUsers',
  props: ['users'],
  data(){
    return {
      userImgList: [],
    }
  },
  methods: {
    getUserImage(user){
      fetch(encodeURI(user.avatar).replace('way.erg.kz', process.env.VUE_APP_ERGWAY_IMG_DOMAIN),{
        headers: {
          Authorization: wayApi.defaults.headers['Authorization']
        }
      }).then(r => 
        r.blob()
      ).then( d => 
        this.$set(this.userImgList, user.id, window.URL.createObjectURL(d))
      );
    },
  },
  mounted(){
    this.users.forEach((user) => {
      this.getUserImage(user);
    })
  }
}
</script>
<style lang="scss">
.way-article-users{
  margin: 24px 0 0 0;
  &__user{
    display: flex;
    margin: 0 0 12px;
  }
  &__user-avatar{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin: 0 12px 0 0;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>