var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"way__page-paginator"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"way__page-paginator__arrow page-paginator__arrow--left",class:{
      'way__page-paginator__arrow--disabled': _vm.loading || !_vm.leftLinkActive
    },on:{"click":function($event){_vm.leftLinkActive && !_vm.loading && _vm.$emit('pageClick', _vm.prevLabel)}}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M12.833 7H1.166m0 0l5.833 5.833M1.166 7l5.833-5.833","stroke":"currentColor","stroke-width":"1.67","stroke-linecap":"round","stroke-linejoin":"round"}})])]),(_vm.numLinks)?_c('div',{staticClass:"way__page-paginator__pages"},[(_vm.numLinks.length < 7)?_vm._l((_vm.numLinks),function(i,k){return _c('PagePaginatorBox',{key:k+'_'+i.label,attrs:{"active":i.active,"label":i.label,"loading":_vm.loading},on:{"click":function($event){!_vm.loading && !i.active && _vm.$emit('pageClick', i.label)}}})}):[(_vm.activeLinkLabel > 2)?_c('PagePaginatorBox',{attrs:{"loading":_vm.loading,"label":1},on:{"click":function($event){!_vm.loading 
          && _vm.activeLinkLabel !== 1 
          && _vm.$emit('pageClick', 1)}}}):_vm._e(),(_vm.activeLinkLabel > 3)?_c('div',{staticClass:"pages__page-box pages__page-box--dots"},[_vm._v("...")]):_vm._e(),(_vm.activeLinkLabel > 1)?_c('PagePaginatorBox',{attrs:{"loading":_vm.loading,"label":_vm.prevLabel},on:{"click":function($event){!_vm.loading 
          && _vm.activeLinkLabel !== _vm.prevLabel 
          && _vm.$emit('pageClick', _vm.prevLabel)}}}):_vm._e(),_c('PagePaginatorBox',{attrs:{"active":"","loading":_vm.loading,"label":_vm.activeLinkLabel}}),(_vm.activeLinkLabel < _vm.lastLabel)?_c('PagePaginatorBox',{attrs:{"loading":_vm.loading,"label":_vm.nextLabel},on:{"click":function($event){!_vm.loading 
          && _vm.activeLinkLabel !== _vm.nextLabel
          && _vm.$emit('pageClick', _vm.nextLabel)}}}):_vm._e(),(_vm.activeLinkLabel < _vm.lastLabel-2)?_c('div',{staticClass:"pages__page-box pages__page-box--dots"},[_vm._v("...")]):_vm._e(),(_vm.activeLinkLabel < _vm.lastLabel-1)?_c('PagePaginatorBox',{attrs:{"loading":_vm.loading,"label":_vm.lastLabel},on:{"click":function($event){!_vm.loading 
          && _vm.activeLinkLabel !== _vm.lastLabel
          && _vm.$emit('pageClick', _vm.lastLabel)}}}):_vm._e()]],2):_c('div'),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"way__page-paginator__arrow page-paginator__arrow--right",class:{
      'way__page-paginator__arrow--disabled': _vm.loading || !_vm.rightLinkActive
    },on:{"click":function($event){_vm.rightLinkActive && !_vm.loading && _vm.$emit('pageClick', _vm.nextLabel)}}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M1.166 7h11.667m0 0L6.999 1.167M12.833 7l-5.834 5.833","stroke":"currentColor","stroke-width":"1.67","stroke-linecap":"round","stroke-linejoin":"round"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }