<template>
  <div
    @click="$emit('click')" 
    class="pages__page-box"
    :class="{
      'pages__page-box--active': active,
      'pages__page-box--loading': loading
    }"
    v-ripple
  >
    <p class="large-medium">{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: 'PagePaginatorBox',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    label: [String, Number],
  }
}
</script>

<style>

</style>