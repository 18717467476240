<template>
  <div class="way__article-info">
      <p class="small text-gray mr-12">
        {{ date && formatedDate(date) || $translate('way_full-card_no-date') }}
      </p>
      <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-4'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 5.5l-.532-.277a.6.6 0 000 .554L1 5.5zm14 0l.532.277a.6.6 0 000-.554L15 5.5zm-14 0l.532.277v-.001l.005-.007a3.179 3.179 0 01.1-.177A11.522 11.522 0 013.26 3.468C4.4 2.299 6.003 1.2 8 1.2V0C5.542 0 3.646 1.351 2.4 2.631A12.72 12.72 0 00.6 4.987a7.479 7.479 0 00-.129.23l-.002.004v.001l-.001.001L1 5.5zm7-4.3c1.996 0 3.601 1.099 4.74 2.269a11.514 11.514 0 011.624 2.123 5.976 5.976 0 01.1.177l.003.007v.001L15 5.5l.532-.277V5.22l-.003-.004-.007-.014-.026-.047a11.235 11.235 0 00-.465-.756 12.72 12.72 0 00-1.43-1.769C12.353 1.351 10.457 0 8 0v1.2zm7 4.3l-.532-.277-.005.008-.018.035-.081.142a11.513 11.513 0 01-1.623 2.124C11.6 8.701 9.996 9.8 8 9.8V11c2.458 0 4.354-1.351 5.6-2.631a12.72 12.72 0 001.8-2.356 7.21 7.21 0 00.096-.169l.026-.047.007-.014.002-.004v-.001l.001-.001L15 5.5zM8 9.8c-1.996 0-3.601-1.099-4.74-2.269a11.521 11.521 0 01-1.624-2.123 6.28 6.28 0 01-.1-.177l-.003-.007v-.001L1 5.5a71.57 71.57 0 00-.532.277v.002a1.443 1.443 0 00.036.065 11.25 11.25 0 00.465.756A12.72 12.72 0 002.4 8.369C3.647 9.649 5.543 11 8 11V9.8zm1.5-4.3A1.5 1.5 0 018 7v1.2a2.7 2.7 0 002.7-2.7H9.5zM8 7a1.5 1.5 0 01-1.5-1.5H5.3A2.7 2.7 0 008 8.2V7zM6.5 5.5A1.5 1.5 0 018 4V2.8a2.7 2.7 0 00-2.7 2.7h1.2zM8 4a1.5 1.5 0 011.5 1.5h1.2A2.7 2.7 0 008 2.8V4z" fill="#667A8B"/></svg>
      <p class="small text-gray mr-12">{{ views }}</p>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-4'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M.578 7.42a5.918 5.918 0 015.91-5.91h.001c1.58 0 3.066.617 4.181 1.734a5.883 5.883 0 011.52 2.61 4.588 4.588 0 012.742 5.026v.002a4.599 4.599 0 01-.78 1.865l.157 1.948a.6.6 0 01-.855.59l-1.707-.809a4.544 4.544 0 01-4.574-1.18c-.224.026-.453.04-.684.04h-.002c-.908 0-1.8-.21-2.61-.608l-2.592.68a.6.6 0 01-.73-.739l.689-2.518a5.903 5.903 0 01-.666-2.73zm5.77 4.714a4.572 4.572 0 01-.409-2.84 4.567 4.567 0 011.88-2.959 4.528 4.528 0 012.982-.816 4.71 4.71 0 00-.98-1.427l.425-.424-.425.424A4.677 4.677 0 006.49 2.71a4.718 4.718 0 00-4.711 4.71c0 .832.216 1.642.628 2.356a.6.6 0 01.06.458l-.48 1.75 1.81-.475a.6.6 0 01.44.054c.647.353 1.372.549 2.113.57zm3.498 1.285c-1.823-.322-3.05-2.077-2.725-3.916a3.367 3.367 0 011.388-2.186 3.33 3.33 0 012.515-.561c1.824.322 3.05 2.078 2.726 3.916v.001a3.395 3.395 0 01-.685 1.522.6.6 0 00-.128.422l.092 1.14-.986-.469a.6.6 0 00-.463-.02 3.342 3.342 0 01-1.734.151z" fill="#667A8B"/></svg>
      <span class="small text-gray mr-12">{{ commentsLength }}</span>
      <div 
        v-if="showShare"
        class="way__article-info__share"
        @click="$emit('shareClick')"
      >
        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-4'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.542a1.625 1.625 0 100 3.25 1.625 1.625 0 000-3.25zm-2.375 4.023a3.375 3.375 0 10-.882-1.511L7.375 7.601a3.375 3.375 0 100 4.795l4.369 2.546A3.378 3.378 0 0015 19.208a3.375 3.375 0 10-2.372-5.775l-4.37-2.547a3.379 3.379 0 000-1.773l4.367-2.548zM6.38 9.142a.893.893 0 00.047.08 1.618 1.618 0 01-.025 1.596A1.624 1.624 0 013.375 10a1.625 1.625 0 013.005-.858zm7.168 5.96a.885.885 0 00.103-.176 1.624 1.624 0 012.974.907 1.625 1.625 0 11-3.077-.73z" fill="#667A8B"/></svg>
        <p class="small text-gray">{{ $translate('way_article-info_share') }}</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'WayArticleInfo',
  props: {
    date: {
      type: String,
    },
    views: {
      type: Number,
    },
    commentsLength: {
      type: Number,
    },
    showShare: {
      type: Boolean,
      default: true,
    }
  },

  methods: {
    formatedDate(date){
      let d = new Date(date.replace(/ /g, "T"));
      let output = ('0'+d.getDate()).slice(-2) + '.' 
        + ('0'+(d.getMonth()+1)).slice(-2) + '.' 
        + d.getFullYear();
      return output;
    }
  }
}
</script>
<style lang="scss">
.way__article-info{
  display: flex;
  align-items: center;
  &__share{
    border: 1px solid #667A8B;
    padding: 4px 8px;
    display: flex;
    border-radius: 8px;
    color: #667A8B;
  }
}
</style>