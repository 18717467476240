<template>
  <div class="way__page-paginator">
    <div 
      class="way__page-paginator__arrow page-paginator__arrow--left"
      :class="{
        'way__page-paginator__arrow--disabled': loading || !leftLinkActive
      }"
      v-ripple
      @click="leftLinkActive && !loading && $emit('pageClick', prevLabel)"
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.833 7H1.166m0 0l5.833 5.833M1.166 7l5.833-5.833" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>
    <div
      v-if="numLinks" 
      class="way__page-paginator__pages"
    >
      <template v-if="numLinks.length < 7">
        <PagePaginatorBox 
          v-for="(i, k) in numLinks"
          :key="k+'_'+i.label"
          :active="i.active"
          :label="i.label"
          :loading="loading"
          @click="!loading && !i.active && $emit('pageClick', i.label)"
        />
      </template>
      <template v-else>
        <!-- FIRST ITEM  -->
        <PagePaginatorBox 
          v-if="activeLinkLabel > 2"
          :loading="loading"
          :label="1"
          @click="
            !loading 
            && activeLinkLabel !== 1 
            && $emit('pageClick', 1)
          "
        />
        <!-- FIRST ITEM END -->
        <div
          v-if="activeLinkLabel > 3" 
          class="pages__page-box pages__page-box--dots"
        >...</div>
        <!-- ACTIVE ITEMS -->
        <PagePaginatorBox
          v-if="activeLinkLabel > 1" 
          :loading="loading"
          :label="prevLabel"
          @click="!loading 
            && activeLinkLabel !== prevLabel 
            && $emit('pageClick', prevLabel)
          "
        />
        <PagePaginatorBox 
          active
          :loading="loading"
          :label="activeLinkLabel"
        />
        <PagePaginatorBox
          v-if="activeLinkLabel < lastLabel" 
          :loading="loading"
          :label="nextLabel"
          @click="!loading 
            && activeLinkLabel !== nextLabel
            && $emit('pageClick', nextLabel)
          "
        />
        <!-- ACTIVE ITEMS END-->
        <div
          v-if="activeLinkLabel < lastLabel-2" 
          class="pages__page-box pages__page-box--dots"
        >...</div>
        <!-- LAST ITEM -->
        <PagePaginatorBox 
          v-if="activeLinkLabel < lastLabel-1"
          :loading="loading"
          :label="lastLabel"
          @click="
            !loading 
            && activeLinkLabel !== lastLabel
            && $emit('pageClick', lastLabel)
          "
        />
        <!-- LAST ITEM END-->
      </template>
    </div>
    <div v-else><!-- creates space if numLinks is empty (just in case) --></div>
    <div 
      class="way__page-paginator__arrow page-paginator__arrow--right"
      :class="{
        'way__page-paginator__arrow--disabled': loading || !rightLinkActive
      }"
      v-ripple
      @click="rightLinkActive && !loading && $emit('pageClick', nextLabel)"
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.166 7h11.667m0 0L6.999 1.167M12.833 7l-5.834 5.833" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>
  </div>
</template>

<script>
import PagePaginatorBox from "@/components/Way/partials/PagePaginatorBox";
export default {
  name: "WayPagePaginator",
  components: {
    PagePaginatorBox
  },
  props: {
    disabled: {
      type: Boolean,
    },
    links: {
      type: Array,
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    numLinks(){
      if (this.links){
        return this.links
          .filter(link => link.label !== "pagination.previous")
          .filter(link => link.label !== "pagination.next");
      } else return []
    },
    activeLinkLabel(){ // Label, not id (Becouse starts from 1)
      return Number(this.numLinks.find(link => link.active).label)
    },
    leftLinkActive(){
      return this.activeLinkLabel !== 0;
    },
    rightLinkActive(){
      return this.activeLinkLabel !== this.numLinks.length - 1;
    },
    prevLabel(){
      return this.activeLinkLabel - 1;
    },
    nextLabel(){
      return this.activeLinkLabel + 1;
    },
    lastLabel(){
      return Number(this.numLinks[this.numLinks.length-1].label);
    }
  },
}
</script>
<style lang="scss">
.way__page-paginator{
  display: flex;
  justify-content: space-between;
  height: 44px;
  &__arrow{
    flex: 0 0 auto;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DDE1E5;
    border-radius: 8px;
    color: #101828;
    &--left {
      margin-right: 12px;  
    }
    &--right{
    margin-left: 12px;
    }
    &--disabled{
      color: #DDE1E5;
    }
  }
  &__pages{
    flex: 0 1 auto;
    display: flex;
    border: 1px solid #DDE1E5;
    border-radius: 4px;
    min-width: 0;
    // overflow-x: auto;
    // scroll-snap-type: x;
    .pages__page-box{
      flex: 1 1 auto;
      text-align: center;
      // max-width: 44px;
      width: 44px;
      padding: 6px 0;
      color: #667A8B;
      user-select: none;
      &--active{
        border: 2px solid #0086E7;
        border-radius: 4px;
        color: #0086E7;
      }
      &--loading{
        color: #DDE1E5;
        border-color: #DDE1E5;
      }
      &--dots{
        flex: 1 3 auto;
      }
    }
  }
}
</style>