<template>
  <div class="way__news">
    <div class="way__news-list">
      <template v-if="loading">
        <v-skeleton-loader 
          v-for="i in Array(3)"
          :key="i"
          type="card"
        />
      </template>
      <template v-else-if="newsList && newsList.length > 0">
        <FullCard 
          v-for="(news, k) in newsList"
          :commentsLength="news.comments_count || 0"
          :date="news.publication_date"
          :description="news.description"
          :id="news.id"
          :image="encodeURI(news.preview_image)"
          :key="'news_'+k"
          :location="news.location && news.location.title || undefined"
          :share-path="'/s/erg_way_form/news/'+news.id"
          :title="news.title"
          :views="news.views"
          @click="() => handleClick(news)"
        />
      </template>
      <template v-else-if="error">
        <ErrorPlaceholder 
          :retry-loading="loading"
          :error="error"
          :title="$translate('way_news-list_error-title')"
          @retry="() => getNews({ 
            page,
            locations 
          })"
        />
      </template>
      <template v-else>
        <p>{{ $translate('way_news-list_empty') }}</p>
      </template>
    </div>
    <PagePaginator 
      v-if="newsLinks"
      key="news"
      :links="newsLinks"
      :loading="loading"
      @pageClick="(page) => getNews({page})"
    />
  </div>
</template>

<script>
import ErrorPlaceholder from '@/components/reusable/ErrorPlaceholder.vue';
import wayApi from "@/helpers/wayApi";
import FullCard from './partials/FullCard.vue';
import PagePaginator from  './partials/PagePaginator.vue';
import { postCustomEvent } from "@/helpers/amplitude";

export default {
  name: 'WayNewsList',
  components: {
    ErrorPlaceholder,
    FullCard,
    PagePaginator
  },
  props: ['page', 'locations'],
  data(){
    return {
      error: undefined,
      loading: true,
      newsList: undefined,
      currentPage: undefined,
      newsLinks: undefined,
    }
  },
  watch: {
    locations(val){
      this.getNews({ 
      page: this.page,
      locations: val,
     });
    }
  },
  mounted(){
    this.getNews({ 
      page: this.page,
      locations: this.locations,
     });
  },
  methods: {
    
    handleClick(news){
      postCustomEvent('Переход внутрь страницы Новости');
      this.$router.replace(
        this.getNewsArticleLink(news.id, this.currentPage)
      ); 
    },

    async getNews({ page, locations }  = {}){
      this.loading = true;
      try {
        let { data } = await wayApi.get('/api/news', { 
          params: { 
            ...(page ? {page} : {}),
            ...(locations?.length ? { locations: `[${locations}]` } : {}),
            per_page: 10,
          }
        });
        if (!data) throw Error(this.$translate('way-news-list_error-no-data'));
        console.log('news:', data);
        this.newsLinks = data?.data?.links;
        this.newsList = data?.data?.data;
        this.currentPage = data?.data?.current_page;
      } catch (err){
        // TODO HAndle
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    getNewsArticleLink: (id, page) => ({
      name: 'WayCategoryArticleById',
      params: {
        id,
        category: 'news',
      },
      query: {
        page: page || 1,
      }
    })
  }
}
</script>
<style lang="scss">
// .way__news{
  
// }
.way__news-list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
}
</style>