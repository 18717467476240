<template>
  <div class="way-top-nav">
    <div
      v-for="(link, k) in links"
      @click="handleClick(link)"
      :key="k"
      class="way-top-nav__link-btn"
      :class="{
        'way-top-nav__link-btn--active': currentCategory === link.to.params.category,
      }"
    >{{ link.title }}</div>
  </div>
</template>

<script>
import { postCustomEvent } from "@/helpers/amplitude";
export default {
  name: 'WayTopNav',
  props: ['currentCategory'],
  data(){
    return {
      links: [
        {
          id: 'bypasses',
          title: this.$translate('way_top-nav_bypasses'),
          to: {
            name: 'WayCategory',
            params: {
              category: 'bypasses'
            }
          }
        },
        {
          id: 'news',
          title: this.$translate('way_top-nav_news'),
          to: {
            name: 'WayCategory',
            params: {
              category: 'news'
            }
          },
        },
        {
          id: 'practices',
          title: this.$translate('way_top-nav_practices'),
          to: {
            name: 'WayCategory',
            params: {
              category: 'practices'
            }
          }
        },
      ]
    }
  },
  methods: {
    handleClick(link){
      if(link.id === 'bypasses'){
        postCustomEvent('Нажатие на кнопку "Обход"')
      } else if (link.id === 'news'){
        postCustomEvent('Нажатие на кнопку "Новости"');
      } else if (link.id === 'practices'){
        postCustomEvent('Нажатие на кнопку "Лучшие практики"')
      }
      this.$router.replace(link.to)
    }
  }
}
</script>
<style lang="scss">
.way-top-nav{
  display: block;
  margin: 20px 0;
  white-space: nowrap;
  &__link-btn{
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
    padding: 6px 16px;
    background: #F6F6F6;
    border-radius: 4px;
    white-space: nowrap;
    &--active{
      background: #0086E7;
      color: #FFF;
    }
  }
}
</style>