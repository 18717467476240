<template>
  <div class="way-top-btns user-select-none">
    <div 
      class="way-top-btns__btn"
      @click="handleLocationClick"
      v-ripple
    >
      <p class="small-medium">{{ $translate('way_top-btns_location') }}</p>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.166 6.417a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm-1.901.828a1.014 1.014 0 01-.099.005h-.5a1 1 0 010-2h.5c.066 0 .13.006.193.019a3.501 3.501 0 016.614 0c.063-.013.127-.019.193-.019h7.167a1 1 0 110 2h-7.167a1.01 1.01 0 01-.099-.005 3.501 3.501 0 01-6.802 0zm8.567 7.005a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm-1.901.829a1.024 1.024 0 01-.098.004H2.666a1 1 0 110-2h7.167c.065 0 .13.007.192.019a3.502 3.502 0 016.614 0c.063-.012.127-.019.193-.019h.5a1 1 0 110 2h-.5c-.033 0-.066-.001-.099-.005a3.501 3.501 0 01-6.802 0z" fill="#232837"/></svg>
    </div>
    <div 
      class="way-top-btns__btn way-top-btns__btn--orange"
      @click="handleIdeaClick"  
      v-ripple
    >
      <p class="small-medium">{{ $translate('way_top-btns_ideas') }}</p>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21 2l-1 1M3 2l1 1M21 16l-1-1M3 16l1-1M9 18h6M10 21h4M12 3C8 3 5.952 4.95 6 8c.023 1.487.5 2.5 1.5 3.5S9 13 9 15h6c0-2 .5-2.5 1.5-3.5h0c1-1 1.477-2.013 1.5-3.5.048-3.05-2-5-6-5z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>
  </div>
</template>

<script>
import { postCustomEvent } from "@/helpers/amplitude";
export default {
  name: "WayTopBtns",
  methods: {
    handleLocationClick(){
      console.log('location click handled');
      postCustomEvent('Нажатие на кнопку "Локация"');
      this.$emit('openCityModal')
    },
    handleIdeaClick(){
      console.log('idea click handled');
      postCustomEvent('Нажатие на кнопку "Подать идею"')
      this.$router.replace({
        name: 'FormPage',
        params: {
          form_name: 'ideas_factory_dashboard_form',
          backToName: 'Way'
        }
      })
    }
  }
}
</script>
<style lang="scss">
.way-top-btns{
  display: flex;
  &__btn{
    flex: 1 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F6F6F6;
    color: #111114;
    padding: 12px;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
    &--orange{
      background: #EF7F1A;
      color: #FFFFFF;
    }
    &:first-child{
      margin-left: 0px;
    }
    &:last-child{
      margin-right: 0px;
    }
  }
}
</style>