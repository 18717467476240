<template>
  <div 
    class="way-article-comment"
  >
    <p>
      <span style="font-weight: 500;">{{ name+' ' }}</span> 
      <span 
        v-if="rootId && commentId"
        class="text-gray">{{ '@'+replyName }}</span> 
      {{ content }}
    </p>
    <div class="mt-4 user-select-none">
      <span class="caption text-gray mr-12">{{ toRuLocale(date) }}</span>
      <span
        v-ripple 
        class="caption-medium text-gray"
        @click="$store.dispatch('way/setReplyUser', {
          id: commentId || id, 
          name,
        })"
      >{{ $translate('way_article-comment_respond') }}</span>
    </div>
    <slot name="default" />
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  props: [
    'content', 
    'date',
    'id', 
    'name', 
    'rootId',
    'commentId',
    'replyName'
  ],
  // computed: {
    // ...mapGetters('way', ['get_name_by_comment_id']),
  // },
  methods: {
    
    toRuLocale(date){
      try {
        let d = new Date(date);
        let options = { 
          month: 'long', 
          day: 'numeric' 
        }
        let output = d.toLocaleDateString('ru-RU', options);
        return output;
      } catch {
        return ''
      }
    },

  }
}
</script>
<style lang="scss">
.way-article-comment{
  margin-top: 24px;
  // &__comment{
  // }
}
</style>