<template>
  <div class="events-top-nav">
    <div
      v-for="(link, k) in links"
      @click="handleClick(link)"
      :key="k"
      class="events-top-nav__link-btn"
      :class="{
        'events-top-nav__link-btn--active': currentType === link.id,
      }"
    >{{ link.title }}</div>
  </div>
</template>

<script>
import { postCustomEvent } from "@/helpers/amplitude";
export default {
  name: 'EventsTopNav',
  props: [
    'currentType',
  ],
  data(){
    return {
      links: [
        {
          id: 'active',
          title: this.$translate('events_top-nav_active'),
          to: {
            name: 'ServiceEvents',
            query: {
              type: 'active'
            }
          }
        },
        {
          id: 'previous',
          title: this.$translate('events_top-nav_previous'),
          to: {
            name: 'ServiceEvents',
            query: {
              type: 'previous'
            }
          },
        },
      ]
    }
  },
  methods: {
    handleClick(link){
      if(link.id === 'active'){
        postCustomEvent('Нажатие на кнопку "Активные" мероприятия')
      } else if (link.id === 'previous'){
        postCustomEvent('Нажатие на кнопку "Пройденные" мероприятия');
      } 
      if (link.id !== this.currentType){
        this.$router.replace(link.to)
      }
    }
  }
}
</script>
<style lang="scss">
.events-top-nav{
  display: block;
  margin: 20px 0;
  white-space: nowrap;
  &__link-btn{
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
    padding: 6px 16px;
    background: #F6F6F6;
    border-radius: 4px;
    white-space: nowrap;
    &--active{
      background: #0086E7;
      color: #FFF;
    }
  }
}
</style>