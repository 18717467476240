<template>
  <main 
    class="layout__body layout__body--events"
  >
    <EventsTopNav 
      :currentType="type"
    />
    <ActiveEventsList
      v-if="type === 'active'"
      :type="type"
      :page="page"
    />
    <p v-else-if="type === 'previous'"> previous events</p> 
    <p v-else>Не известная тип событий "{{ type }}"</p>
  </main>
</template>

<script>
import EventsTopNav from '@/components/Events/TopNav';
import ActiveEventsList from '@/components/Events/ActiveEventsList';
import { postCustomEvent } from "@/helpers/amplitude";
import { backNavigateQueue } from "@/helpers/app.js";

export default {
  name: 'WayView',
  components: {
    ActiveEventsList,
    EventsTopNav,
  },
  props: {
    type: {
      type: String,
      default: 'active'
    },
    page: {
      type: Number,
    }
  },
  mounted(){
    postCustomEvent('Переход на сервис Event-ов');
    backNavigateQueue.set(this.handleBackNavigation);
    this.$store.dispatch('layout/setHeaderTitle', 'Events');
  },
  methods: {

    handleBackNavigation(){
      this.$router.replace({
        name: 'MainPage',
      })
    },

  },
}
</script>