<template>
  <div class="layout__body layout__body--way way-article">
    <template v-if="loading">
      <v-skeleton-loader type="article" />
    </template>
    <template v-else-if="error">
      <p>{{ $translate('way_categry_error-title')}}</p>
      <p>{{ error }}</p>
    </template>
    <template v-else>
      <div 
        v-if="location || rating"
        class="way-article__top"
      >
        <p
          v-if="location" 
          class="d-flex small text-gray"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-4'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.5 2.5l-15 5.65 7.075 2.448 2.449 6.902 5.476-15z" stroke="#667A8B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <span>{{ location }}</span>
        </p>
        <FullCardRating
          v-if="rating >= 0"
          style="way-article__top__rating"
          :value="rating"
        />
      </div>
      <p class="h5 mt-8" style="line-height: 28px;">{{ title }}</p>
      <ArticleInfo 
        class="mt-8"
        :commentsLength="commentsLength"
        :date="date"
        :views="views"
        @shareClick="handleShareClick"
      />
      <ArticleUsers 
        v-if="users && users.length > 0" 
        :users="users"
      />
      <div class="way-article__line"></div>
      <template v-if="description">
        <p>{{ description }}</p>
        <div class="way-article__line"></div>
      </template>
      <ArticleContent 
        :content="content"
      />
      <ArticleVideo 
        v-if="videoLink"
        :link="videoLink"
      />
      <ArticleYoutubeVideo 
        v-if="youtubeSrc"
        :src="youtubeSrc"
      />
      <ArticleCarousel 
        v-if="images && images.length > 0"
        :images="images"
      />
      <div class="way-article__line"></div>
      <p class="text-gray label-small">{{ $translate('way_category_comments-title') }} ({{ commentsLength }})</p>
      <ArticleComments />
    </template>
    <!-- <p>{{category}}</p>
    <p>{{id}}</p> -->
  </div>
</template>

<script>
import ArticleCarousel from '@/components/Way/ArticleCarousel.vue';
import ArticleComments from '@/components/Way/ArticleComments.vue';
import ArticleContent from '@/components/Way/ArticleContent.vue';
import ArticleInfo from '@/components/Way/partials/ArticleInfo.vue';
import ArticleUsers from '@/components/Way/ArticleUsers.vue';
import ArticleVideo from '@/components/Way/ArticleVideo.vue';
import ArticleYoutubeVideo from '@/components/Way/ArticleYoutubeVideo.vue';
import FullCardRating from '@/components/Way/partials/FullCardRating.vue';
import wayApi from '@/helpers/wayApi';
import bridge from '@/helpers/bridge';
import { backNavigateQueue } from "@/helpers/app.js";
import { getYouTubeID } from "@/helpers/utils"
const categorySlugMap = {
  'bypasses': 'bypasses-productions',
  'news': 'news',
  'practices': 'best-practices',
}

export default {
  name: 'WayCategoryArticleById',
  components: { 
    ArticleCarousel,
    ArticleComments,
    ArticleContent,
    ArticleInfo,
    ArticleUsers,
    ArticleVideo,
    ArticleYoutubeVideo,
    FullCardRating,
  },
  props: ['category', 'id', 'page'],
  data(){
    return {
      commentsLength: undefined,
      content: undefined,
      date: undefined,
      error: undefined,
      images: undefined,
      loading: true,
      location: undefined,
      rating: undefined,
      title: undefined,
      views: undefined,
      videoLink: undefined,
      youtubeSrc: undefined,
      users: [],
    }
  },

  methods: {
    
    async getArticle(){
      try {
        let slug = categorySlugMap[this.category];
        let res = await wayApi.get(`/api/${slug}/${this.id}`);
        if (res?.data?.data){
          let data = res?.data?.data;
          this.commentsLength= data.comments_count;
          this.content = data.content;
          this.date = data.publication_date;
          this.description = data.description;
          this.location = data?.location?.title;
          this.rating = data.rating;
          this.title = data.title;
          this.views = data.views;
          this.images = data.images;
          this.videoLink = data.video_link;
          if (data.youtube_url){
            let id = getYouTubeID(data.youtube_url)
            this.youtubeSrc = id ? ('https://www.youtube.com/embed/' + id) : '';
          }
          if (data.auditor){
            this.users = [data.auditor]
          }
          if (data.responsible_person){
            this.users = [
              ...this.users,
              data.responsible_person
            ]
          }
          this.$store.dispatch('way/setComments', data.comments);
        } else {
          throw Error(this.$translate('way_category_response-no-data'))
        }
      } catch (err){
        this.error = err;
      } finally {
        this.loading = false
      }
    },

    handleShareClick(){
      let { category, id } = this;
      let encoded_to_url = encodeURI(`/s/erg_way_form/${category}/${id}`);
      bridge.share('https://smarterg.app.link'
        +'?command=apps'
        +'&id=7ef949af-9058-11eb-bbd2-768a8a250dd0'
        +'&ergID=7ef949af-9058-11eb-bbd2-768a8a250dd0'
        +`&to_url=${encoded_to_url}`
      )
    },

    handleBackNavigation(){
      let { page, id, category } = this;
      this.$router.replace({
        name: 'WayCategory',
        params: {
          category,
          id,
        },
        query: {
          page
        }
      })
    }
  },

  mounted(){
    this.getArticle();
    backNavigateQueue.set(this.handleBackNavigation)
  },

  destroyed(){
    this.$store.dispatch('way/clearReplyUser');
    this.$store.dispatch('way/clearComments');
  },
}
</script>

<style lang="scss">
.way-article{
  &__top{
    display: flex;
    &__rating{
      margin-left: auto;
    }
  }
  &__line{
    margin: 24px 0;
    border-bottom: 1px solid #E0E4E9;
  }
}
.layout__body{
  &.way-article{
    padding-bottom: calc(92px + env(safe-area-inset-bottom, 0px));
  }
}
</style>