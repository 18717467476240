<template>
  <div class="events-list">
    <div class='events-list__list'>
      <template v-if="loading">
        <v-skeleton-loader 
          v-for="i in Array(3)"
          :key="i"
          type="card"
        />
      </template>
      <template v-else-if="activeEventsList">
        <FullCard 
          v-for="(event, k) in activeEventsList"
          :commentsLength="event.comments_count"
          :date="event.publication_date"
          :description="event.description"
          :id="k"
          :image="encodeURI(event.imgUrl)"
          :key="'event_'+k"
          :title="event.title"
          :views="event.views"
          @click="() => handleClick(event)"
          />
          <!-- :location="event.location && event.location.title || undefined"
          :rating="event.rating.value" -->
          <!-- :share-path="'/s/erg_way_form/bypasses/'+event.id" -->
      </template>
      <template v-else-if="error">
        <ErrorPlaceholder 
          :retry-loading="loading"
          :error="error"
        />
          <!-- :title="$translate('way_bypasses-list_error-title')"
          @retry="() => getActiveEvents({ 
            page,
            locations 
          })" -->
      </template>
      <template v-else>
        <p>{{ $translate('way-bypasses-list_empty') }}</p>
      </template>
    </div>
    <!-- <PagePaginator 
      v-if="bypassesLinks"
      :links="bypassesLinks"
      :current="currentPage"
      :loading="loading"
      @pageClick="(page) => getActiveEvents({page, locations})"
    /> -->
  </div>
</template>
<script>
import ErrorPlaceholder from '@/components/reusable/ErrorPlaceholder.vue';
import baseApi from "@/helpers/baseApi";
import FullCard from './partials/FullCard.vue';
// import PagePaginator from  './partials/PagePaginator.vue';
import { postCustomEvent } from "@/helpers/amplitude";

export default {
  name: 'ActiveEventsList',
  components: {
    ErrorPlaceholder,
    FullCard,
    // PagePaginator,
  },
  props: [
    'page',
  ],
  data(){
    return {
      currentPage: undefined,
      error: undefined,
      loading: true,
      activeEventsList: undefined,
      // activeEventsLinks: undefined,
    }
  },
  mounted(){
    this.getActiveEvents({ 
      page: this.page, 
    });
  },
  methods: {
    
    handleClick(event){
      postCustomEvent('Переход внутрь страницы Событий');
      this.$router.replace(
        this.getActiveEventById(event.id, this.currentPage)
      ); 
    },

    async getActiveEvents({ page } = {}){
      this.loading = true;
      try {
        let { data } = await baseApi({
          method: 'get',
          url: 'api/avatar/events/active',
          params: {
            ...(page ? {page} : {}),
            // per_page: 10,
          }
        });
        console.log('events:', data);
        this.activeEventsList = data.content;
        // this.activeEventsList = data?.data?.data;
        // this.currentPage = data?.data?.current_page;
      } catch (err){
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    // getActiveEventById: (id, page) => ({
    //   name: 'WayCategoryArticleById',
    //   params: {
    //     id,
    //     category: 'bypasses',
    //   },
    //   query: {
    //     page: page || 1,
    //   }
    // }),

  }
}
</script>
<style lang="scss">
.events-list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
}
</style>