<template>
  <main 
    class="layout__body layout__body--way"
  >
    <WayTopBtns 
      @openCityModal="showCityModal = true"
    />
    <WayTopNav 
      :currentCategory="category"
    />
    <WayBypassesList
      v-if="category === 'bypasses'"
      :locations="chosenCities"
      :page="page"
    />
    <WayNewsList
      v-else-if="category === 'news'"
      :locations="chosenCities"
      :page="page"
    />
    <WayPracticesList
      v-else-if="category === 'practices'"
      :locations="chosenCities"
      :page="page"
    />
    <p v-else>{{ $translate('way_category-unknown') }}</p>
    <BottomDrawer 
      v-if="showCityModal"
      :title="$translate('way_city-modal_title')"
      height="100%"
      @close="showCityModal = false"
    >
      <ModalContentCity 
        :chosen="chosenCities"
        @setChosenList="(val) => this.chosenCities = val"
        @close="showCityModal = false"
      />
    </BottomDrawer>
  </main>
</template>

<script>
import BottomDrawer from '@/components/reusable/BottomDrawer';
import ModalContentCity from '@/components/Way/ModalContentCity';
import WayTopBtns from '@/components/Way/TopBtns';
import WayTopNav from '@/components/Way/TopNav';
import WayBypassesList from '@/components/Way/CategoryBypassesList';
import WayNewsList from '@/components/Way/CategoryNewsList';
import WayPracticesList from '@/components/Way/CategoryPracticesList';
import { postCustomEvent } from "@/helpers/amplitude";
import { backNavigateQueue } from "@/helpers/app.js";

export default {
  name: 'WayView',
  components: {
    BottomDrawer,
    ModalContentCity,
    WayBypassesList,
    WayNewsList,
    WayPracticesList,
    WayTopBtns,
    WayTopNav,
  },
  props: {
    category: {
      type: String,
      default: 'bypasses'
    },
    page: {
      type: Number,
    }
  },
  data(){
    return {
      chosenCities: [],
      showIdeaModal: false,
      showCityModal: false,
    }
  },
  mounted(){
    postCustomEvent('Переход на сервис ERG Way');
    backNavigateQueue.set(this.handleBackNavigation);
    this.$store.dispatch('layout/setHeaderTitle', 'ERG Way');
  },
  methods: {

    handleBackNavigation(){
      this.$router.replace({
        name: 'MainPage',
      })
    },

  },
}
</script>