<template>
  <v-responsive
    :aspect-ratio="16/9"
    content-class="d-flex justify-center"
  >
    <iframe
      :src="src"
      width="100%"
      frameborder="0"
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      "
      allowfullscreen
    ></iframe>
  </v-responsive>
</template>

<script>
export default {
  name: 'ArticleYoutubeVideo',
  props: ['src']
}
</script>