<template>
  <div 
    @touchstart.stop
    @touchend.stop
    @touchmove.stop
    class="article-carousel-container"
  >
    <Flicking
      :options="{ 
        align: { 
          panel: 0, 
          camera: 24,
        },
        adaptive: true,
        autoResize: true,
        overflow: true,
        collectStatistics: false,
      }"
      :tag="'div'"
      :viewportTag="'div'"
      :cameraTag="'div'"
      :preventClickOnDrag="true"
      :plugins="plugins"
    >
      <div 
        v-for="image in images"
        :key="image.id"
        class="article-carousel__card"
        :class="image.color_id >=0 ? `bg-${image.color_id}` : ''"
      >
        <v-img 
          :aspect-ratio="328/180"
          class="article-carousel__card__img"
          :src="srcList[image.id]" 
          alt=""
        />
        <div class="article-carousel__card__content">
          <p class="medium mb-8">{{ image.title }}</p>
          <p class="caption">{{ image.description }}</p>
        </div>
      </div>
      <div slot="viewport" class="flicking-pagination"></div>
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import wayApi from "@/helpers/wayApi";

export default {
  name: 'WayArticleCarousel',
  components: {
    Flicking,
  },
  props: {
    images: {
      type: Array,
    }
  },
  data(){
    return {
      plugins: [new Pagination({ type: 'bullet' })],
      srcList: [],
      img_url_replace: process.env.VUE_APP_ERGWAY_IMG_DOMAIN,
    }
  },
  methods: {
    getImage(image){
      fetch(encodeURI(image.image).replace('way.erg.kz', this.img_url_replace),{
        headers: {
          Authorization: wayApi.defaults.headers['Authorization']
        }
      }).then(r => 
        r.blob()
      ).then( d => 
        this.$set(this.srcList, image.id, window.URL.createObjectURL(d))
      );
    }
  },
  mounted(){
    this.images.forEach((image) => {
      this.getImage(image);
    })
  },
}
</script>
<style>
</style>
<style lang="scss">
@import "~@egjs/vue-flicking/dist/flicking.css";
@import "~@egjs/flicking-plugins/dist/pagination.css";

.article-carousel-container{
  margin: 8px -24px 1rem;
  // min-height: 156px;
  position: relative; // so the header and footer overlap
  z-index: 0;
  user-select: none;
  .flicking-viewport {
    padding-bottom: 2rem !important;
    transition: height 500ms;
  }
  .flicking-pagination-bullet{
    background: #667A8B;
    opacity: 0.4;
    border-radius: 5px;
    height: 4px;
    width: 4px;
  }
  .flicking-pagination-bullet-active{
    background: #667A8B;
    opacity: 1;
    width: 16px;
  }
}
.article-carousel__card{
  margin-right: 28px;
  width: calc(100% - 48px);
  background: #667A8B;
  color: #FFFFFFCC;
  border-radius: 8px;
  overflow: hidden;
  .article-carousel__card__img{
    width: 100%;
    height: auto;
    user-select: none;
    pointer-events: none;
  }
  .article-carousel__card__content{
    padding: 12px 8px;
  }
  // &.bg-0{

  // }
  &.bg-1{
    background: #FBBF24;
    color: #151C22;
  }
  &.bg-2{
    background: #90BC34;
    color: #FFF;
  }
  &.bg-3{
    background: #b5241c;
    color: #FFF;
  }

}
</style>