<template>
  <div class="way__practices">
    <div class="way__practices-list">
      <template v-if="loading">
        <v-skeleton-loader 
          v-for="i in Array(3)"
          :key="i"
          type="card"
          width="47%"
        />
      </template>
      <template v-else-if="practicesList && practicesList.length > 0">
        <HalfCard
          v-for="(item, k) in practicesList"
          :img="item.preview_image"
          :key="'practices_'+k"
          :title="item.title"
          @click="() => handleClick(item)"
        />
      </template>
      <template v-else-if="error">
        <ErrorPlaceholder 
          :retry-loading="loading"
          :error="error"
          :title="$translate('way_practices-list_error-title')"
          @retry="() => getPractices({ 
            page,
            locations 
          })"
        />
      </template>
      <template v-else>
        <p>{{ $translate('way_practices-list_empty')}}</p>
      </template>
    </div>
    <PagePaginator 
      v-if="practicesLinks"
      :links="practicesLinks"
      :current="currentPage"
      :loading="loading"
      @pageClick="(page) => getPractices({page})"
    />
  </div>
</template>

<script>
import ErrorPlaceholder from '@/components/reusable/ErrorPlaceholder.vue';
import wayApi from "@/helpers/wayApi";
import HalfCard from './partials/HalfCard.vue';
import PagePaginator from  './partials/PagePaginator.vue';
import { postCustomEvent } from "@/helpers/amplitude";

export default {
  name: 'WayPracticesList',
  props: ['page', 'locations'],
  components: {
    ErrorPlaceholder,
    HalfCard,
    PagePaginator,
  },
  data(){
    return {
      currentPage: undefined,
      error: undefined,
      loading: true,
      practicesList: undefined,
      practicesLinks: undefined,
    }
  },
  watch: {
    locations(val){
      this.getPractices({ 
      page: this.page,
      locations: val,
     });
    }
  },
  mounted(){
    this.getPractices({ 
      page: this.page,
      locations: this.locations,
    });
  },
  methods: {

    handleClick(practice){
      postCustomEvent('Переход внутрь страницы Лучшие практики');
      this.$router.replace(
        this.getPracticeArticleRoute(practice.id, this.currentPage)
      ); 
    },
    
    async getPractices({ page, locations } = {}){
      this.loading = true
      try {
        let { data } = await wayApi.get('/api/best-practices', { 
          params: { 
            ...(page ? {page} : {}),
            ...(locations?.length && { locations: `[${locations}]` }),
            per_page: 10,
          }
        });
        console.log('best-practices:', data);
        if (!data) throw Error(this.$translate('way_practices-list_error-no-data'));
        this.practicesLinks = data?.data?.links;
        this.practicesList = data?.data?.data;
        this.currentPage = data?.data?.current_page;
      } catch (err){
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    getPracticeArticleRoute: (id, page) => ({
      name: 'WayCategoryArticleById',
      params: {
        id,
        category: 'practices',
      },
      query: {
        page: page || 1,
      }
    }),
  }
}
</script>
<style lang="scss">
.way__practices{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  // height: 100%;
  .way__practices-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>