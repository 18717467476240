<template>
  <div 
    class="way__full-card-rating"
    :style="{
      background: ratingColor(value)
    }"
  >
    <div
      v-for="(el, k) in Array(5)" 
      :key="k" 
      class="way__full-card-rating__star"
      :class="{
        'way__full-card-rating__star--white': k < value
      }"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.787 5.38l3.69.355c1.285.123 1.68 1.378.687 2.202l-2.89 2.402 1.073 3.913c.353 1.285-.74 2.062-1.835 1.29L8 13.06 4.49 15.54c-1.09.77-2.188-.004-1.836-1.29l1.073-3.912-2.89-2.402C-.16 7.11.233 5.86 1.523 5.735l3.69-.354L6.84 1.63C7.35.459 8.653.46 9.161 1.633l1.626 3.749z" fill="currentColor"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullCardRating',
  props: {
    value: {
      type: Number,
    }
  },
  methods: {
    ratingColor: (rating) => {
      if (rating > 3) {
        return '#90BC34';
      } else if (rating > 2){
        return '#FBBF24';
      } else {
        return '#F5463B';
      }
    }
  }
}
</script>
<style lang="scss">
.way__full-card-rating{
  display: flex;
  padding: 2px 6px;
  border-radius: 4px;
  &__star{
    color: rgba(246, 246, 246, 0.57);
    &--white{
      color: #FFF;
    }
  }
}
</style>