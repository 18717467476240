var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article-carousel-container",on:{"touchstart":function($event){$event.stopPropagation();},"touchend":function($event){$event.stopPropagation();},"touchmove":function($event){$event.stopPropagation();}}},[_c('Flicking',{attrs:{"options":{ 
      align: { 
        panel: 0, 
        camera: 24,
      },
      adaptive: true,
      autoResize: true,
      overflow: true,
      collectStatistics: false,
    },"tag":'div',"viewportTag":'div',"cameraTag":'div',"preventClickOnDrag":true,"plugins":_vm.plugins}},[_vm._l((_vm.images),function(image){return _c('div',{key:image.id,staticClass:"article-carousel__card",class:image.color_id >=0 ? ("bg-" + (image.color_id)) : ''},[_c('v-img',{staticClass:"article-carousel__card__img",attrs:{"aspect-ratio":328/180,"src":_vm.srcList[image.id],"alt":""}}),_c('div',{staticClass:"article-carousel__card__content"},[_c('p',{staticClass:"medium mb-8"},[_vm._v(_vm._s(image.title))]),_c('p',{staticClass:"caption"},[_vm._v(_vm._s(image.description))])])],1)}),_c('div',{staticClass:"flicking-pagination",attrs:{"slot":"viewport"},slot:"viewport"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }