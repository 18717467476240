<template>
  <div 
    class="way__half-card"
    @click="$emit('click')"
  >
    <v-img 
      aspect-ratio="1.26"
      :src="src"
      :style="{
        borderRadius: '2px',
      }"
    />
    <p class="mt-16 mb-24">{{ title }}</p>
  </div>
</template>

<script>
import wayApi from "@/helpers/wayApi";

export default {
  name: 'WayHalfCard',
  props: {
    img: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data(){
    return {
      src: undefined,
    }
  },
  methods: {
    getImage(){
      fetch(this.img.replace('way.erg.kz', process.env.VUE_APP_ERGWAY_IMG_DOMAIN),{
        headers: {
          Authorization: wayApi.defaults.headers['Authorization']
        }
      }).then(r => 
        r.blob()
      ).then( d => 
        this.src=window.URL.createObjectURL(d)
      );
    }
  },
  mounted(){
    this.getImage();
  },
}
</script>
<style>
.way__half-card{
  display: block;
  width: 47%;
}
</style>