<template>
  <div 
    v-intersect="{ 
      handler: onIntersect,
      options: {
        rootMargin: '-150px',
      }
    }"
    class="way-article-comments"
  >
    <ArticleComment
      v-for="(rootComment) in rootComments"
      :key="rootComment.id"
      :content="rootComment.content"
      :date="rootComment.created_at"
      :id="rootComment.id"
      :name="rootComment.name"
      :reply-name="rootComment.reply_name"
    >
      <ArticleComment
        v-for="(childComment) in repliesByCommentIdMap[rootComment.id]"
        class="ml-8"
        :key="childComment.id"
        :content="childComment.content"
        :date="childComment.created_at"
        :rootId="rootComment.id"
        :id="childComment.id"
        :name="childComment.name"
        :commentId="childComment.comment_id"
        :reply-name="childComment.reply_name"
      />
    </ArticleComment>
  </div>
</template>

<script>
import ArticleComment from './partials/ArticleComment.vue';
import { mapState } from "vuex";
export default {
  components: { ArticleComment },
  name: 'WayArticleComments',
  computed: {
    ...mapState('way', ['comments']),
    rootComments(){
      return this.comments.filter((comment) => (comment.comment_id === 0))
    },

    repliesByCommentIdMap(){
      let output = {}
      try {
        output = this.comments.reduce((acc, comment) => {
          if (comment.comment_id > 0){
            let root_id;
            // check if comment exists in comments map
            Object.keys(acc).forEach((key) => {
              let hasReply = acc[key]?.length && acc[key].some((el) => el.comment_id === comment.comment_id)
              if (hasReply){
                root_id = key;
              }
            })
            console.log(root_id);
            if (root_id){
              acc[root_id] = [
                ...acc[root_id],
                comment
              ]
            } else {
              acc[comment.comment_id] = [
                ...(acc[comment.comment_id] ?? []),
                comment,
              ]
            }
          }
          return acc;
        },{})
      } catch (err){
        console.log(err);
      }
      return output;
    },
  },
  methods: {
    
    onIntersect(entries, observer, isIntersecting){
      console.log(entries, observer, isIntersecting);
      if (entries[0].isIntersecting){
        this.$store.dispatch('way/showReplyInputs');
      } else {
        this.$store.dispatch('way/hideReplyInputs');
      }
    },
    
  }
}
</script>