<template>
  <div class="way__bypasses">
    <div class='way__bypasses__list'>
      <template v-if="loading">
        <v-skeleton-loader 
          v-for="i in Array(3)"
          :key="i"
          type="card"
        />
      </template>
      <template v-else-if="bypassesList">
        <FullCard 
          v-for="(bypass, k) in bypassesList"
          :commentsLength="bypass.comments_count"
          :date="bypass.publication_date"
          :description="bypass.description"
          :id="k"
          :image="encodeURI(bypass.preview_image)"
          :key="'bypass_'+k"
          :location="bypass.location && bypass.location.title || undefined"
          :rating="bypass.rating.value"
          :share-path="'/s/erg_way_form/bypasses/'+bypass.id"
          :title="bypass.title"
          :views="bypass.views"
          @click="() => handleClick(bypass)"
        />
      </template>
      <template v-else-if="error">
        <ErrorPlaceholder 
          :retry-loading="loading"
          :error="error"
          :title="$translate('way_bypasses-list_error-title')"
          @retry="() => getBypasses({ 
            page,
            locations 
          })"
        />
      </template>
      <template v-else>
        <p>{{ $translate('way-bypasses-list_empty') }}</p>
      </template>
    </div>
    <PagePaginator 
      v-if="bypassesLinks"
      :links="bypassesLinks"
      :current="currentPage"
      :loading="loading"
      @pageClick="(page) => getBypasses({page, locations})"
    />
  </div>
</template>
<script>
import ErrorPlaceholder from '@/components/reusable/ErrorPlaceholder.vue';
import wayApi from "@/helpers/wayApi";
import FullCard from './partials/FullCard.vue';
import PagePaginator from  './partials/PagePaginator.vue';
import { postCustomEvent } from "@/helpers/amplitude";

export default {
  name: 'WayBypassesList',
  components: {
    ErrorPlaceholder,
    FullCard,
    PagePaginator,
  },
  props: ['page', 'locations'],
  data(){
    return {
      currentPage: undefined,
      error: undefined,
      loading: true,
      bypassesList: undefined,
      bypassesLinks: undefined,
    }
  },
  watch: {
    locations(value){
      this.getBypasses({ 
        page: this.page, 
        locations: value,
      }); 
    } 
  },
  mounted(){
    this.getBypasses({ 
      page: this.page, 
      locations: this.locations 
    });
  },
  methods: {
    
    handleClick(bypass){
      postCustomEvent('Переход внутрь страницы Обхода');
      this.$router.replace(
        this.getBypassArticleLink(bypass.id, this.currentPage)
      ); 
    },

    async getBypasses({ page, locations } = {}){
      this.loading = true;
      try {
        let { data } = await wayApi({
          method: 'get',
          url: '/api/bypasses-productions',
          params: {
            ...(page ? {page} : {}),
            ...(locations?.length ? { locations: `[${locations}]` } : {}),
            per_page: 10,
          }
        });
        console.log('bypasses:', data);
        this.bypassesList = data?.data?.data;
        this.bypassesLinks = data?.data?.links;
        this.currentPage = data?.data?.current_page;
      } catch (err){
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    getBypassArticleLink: (id, page) => ({
      name: 'WayCategoryArticleById',
      params: {
        id,
        category: 'bypasses',
      },
      query: {
        page: page || 1,
      }
    }),

  }
}
</script>
<style lang="scss">
.way__bypasses{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
}
</style>