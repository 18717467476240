<template>
  <div 
    :style="{
      paddingTop: calculatedHeight + 'px'
    }"
  >
    <v-slide-y-transition>
      <div 
        v-show="showReplyInputs || focused || loading"
        ref="comment-reply"
        class="comment-reply"
      > 
        <v-progress-linear 
          v-if="loading"
          :indeterminate="true"
          :height="2"
          :color="theme_color"
        />
        <input
          v-model="replyName"
          ref="reply-name-input"
          class="comment-reply__name-input"
          type="text"
          :placeholder="$translate('way_comments_name-input-placeholder')"
          @focus="focused = true"
          @blur="focused = false"
          :disabled="loading"
        >
        <div class="comment-reply__content">
          <span 
            class="comment-reply__content__name"
            :style="{
              top: -textAreaScrollTop + 'px',
            }"
            ref="name-area"
            v-if="reply_name && reply_id">
            {{ '@'+reply_name }}
          </span>
          <textarea 
            v-model="replyContent"
            ref="reply-input"
            :disabled="loading"
            :style="{
              textIndent: textAreaOffset + 'px',
            }"
            class="comment-reply__content-input"
            :placeholder="
              $translate(reply_name 
                ? 'way_comments_reply-input-placeholder' 
                : 'way_comments_comment-input-placeholder'
              )
            "
            name="text" 
            id="" 
            rows="2"
            @focus="focused = true"
            @blur="focused = false"
            @scroll="handleContentScroll"
            @keydown.delete="handleContentDelete"
          />
        </div>
        <!--  50px -->
        <v-slide-y-reverse-transition >
          <div 
            v-if="replyName && replyContent"
            class="comment-reply__submit"
            @click="postComment"
          >
            <v-progress-circular
              v-if="loading"
              :size="20"
              :width="2"
              indeterminate
              color="#FFF"
            ></v-progress-circular>
            <p 
              v-else 
              class="small-medium"
            >{{ $translate('way_comments_comment-submit-btn') }}</p>
          </div>
      </v-slide-y-reverse-transition>
        </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { wayApi } from '@/helpers/wayApi';
import { mapState } from 'vuex';

const categoryCommentModelMap = {
  'bypasses': 'production',
  'news': 'news',
  'practices': 'practise',
}

export default {
  name: 'WayArticleFooterView',
  props: ['category', 'id'],
  computed: {
    ...mapState('way', [
      'showReplyInputs',
      'reply_id',
      'reply_name',
    ]),
    ...mapState("layout", ['theme_color']),
  },
  data(){
    return {
      replyName: undefined,
      replyContent: undefined,
      focused: false,
      loading: false,
      calculatedHeight: 150,
      textAreaOffset: 0,
      textAreaScrollTop: 0,
    }
  },
  watch: {
    showReplyInputs(val){
      if (val){
        this.$nextTick(() => {
          this.calculatedHeight = this.$refs['comment-reply'].offsetHeight;
        })
      } else {
        this.calculatedHeight = 150;
      }
    },
    reply_name(val){
      if (val){
        this.focused = true;
      }
      this.$nextTick(() => {
        this.textAreaOffset = this.$refs['name-area']?.offsetWidth 
          ? this.$refs['name-area'].offsetWidth + 4
          : 0;
        if (val){
          console.log('has val')
          this.$refs['reply-name-input'].focus();
        }
      })
      
    }
  },
  methods: {
    async postComment(){
      this.loading = true;
      try {
        let user_id = this.$store.state.user.aituMeData.id;
        let res = await wayApi.post('/api/send-comment', {
          name: this.replyName,
          content: this.replyContent,
          model: categoryCommentModelMap[this.category],
          model_id: Number(this.id),
          user_id,
          user_ip: '0.0.0.0',
          ...(this.reply_id ? {comment_id: this.reply_id } : {}),
        });
        console.log(res);
        if (res?.data?.data){
          this.$store.dispatch('way/addComment', res?.data?.data);
          this.$store.dispatch('way/clearReplyUser');
          this.replyName = '';
          this.replyContent = '';
        }
      } catch (err){
        this.$store.dispatch('layout/alert', err);
      }
      this.loading = false;
    },

    handleContentDelete(e){
      // remove reply user
      if (this.reply_name || this.reply_id >= 0){
        let start = 0;
        let end = 0;
        let target = e.target;
        if (target.selectionStart || target.selectionStart == '0') {
          start = target.selectionStart,
          end = target.selectionEnd;
        } 
        console.log(start, end)
        if (start === 0 && end === 0){
          this.$store.dispatch('way/clearReplyUser');
        }
      }
    },

    handleContentScroll(e){
      this.textAreaScrollTop = e.target.scrollTop;
    }
  }
}
</script>
<style lang="scss">
.comment-reply{
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: #FFF;
  @supports(padding: max(0px)) {      // check if ios device 
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
  &__name-input{
    display: block;
    outline: none;
    width:100%;
    font-family: 'TTCommons';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    padding: 12px 16px;
    ::placeholder{
      color: #667A8B;
    }
  }
  &__content{
    position: relative;
    margin: 16px 16px;
    overflow: hidden;
    &__name{
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'TTCommons';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.02em;
      user-select: none;
    }
  }

  &__content-input{
    display: block;
    resize: none;
    width: 100%;
    padding: 0;
    font-family: 'TTCommons';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    outline: none;
  }
  &__submit{
    background: #EF7F1A;
    color: #FFFFFF;
    user-select: none;
    padding: 16px;
    text-align: center;
  }
}
</style>