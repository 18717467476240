<template>
  <div class="way__city-modal">
    <div class="way__city-modal__header">
      <div 
        class="way__city-modal__header__arrow-div"
        @click="$emit('close');">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'26'" v-bind:height="'26'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 19l-7-7 7-7" stroke="#1D1F23" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
      <p class="medium">{{ $translate('way_modal-content-city_title') }}</p>
    </div>
    <div class="way__city-modal__content">
      <template v-if="loading">
        <div 
          v-for="i in 5"
          :key="i"
          class="way__city-modal__content__item">
          <v-skeleton-loader 
            type="text" 
            :height="24"
            :width="48*(i*2%3+1)"
          />
          <v-skeleton-loader 
            type="button" 
            :width="24"
            :height="24"
          />
        </div>
      </template>
      <template v-else-if="list && list.length > 0">
        <SearchInput 
          v-model="searchValue"
        />
        <div 
          v-for="(city, k) in filteredList"
          :key="k"
          class="way__city-modal__content__item"
        >
          <p 
            class="item__title"
            @click="toggleChecked(city.id)"
          >
            {{ city.title }}
          </p>
          <v-simple-checkbox
            ref="checkbox"
            color="#267CCC"
            :value="chosen.includes(city.id)"
            :ripple="false"
            @click="toggleChecked(city.id)"
          ></v-simple-checkbox>
        </div>
      </template>
      <template v-else-if="list && list.length === 0">
        <p>{{ $translate('way_modal-content-city_empty') }}</p>
      </template>
      <template v-else>
        <p>{{ $translate('way_modal-content-city_error-title') }}</p>
        <p>{{ error }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/partials/SearchInput.vue";
import wayApi from "@/helpers/wayApi"
export default {
  name: 'ModalContentCity',
  components: {
    SearchInput,
  },
  props: ['chosen'],
  data(){
    return {
      error: undefined,
      list: undefined,
      loading: true,
      searchValue: '',
    }
  },
  computed: {
    filteredList(){
      return this.searchValue 
        ? this.list.filter((city) => (
          city.title.toLowerCase().includes(this.searchValue.toLowerCase()))
        )
        : this.list
    }
  },
  methods: {
    
    toggleChecked(id){
      if (this.chosen.includes(id)){
        this.$emit('setChosenList', this.chosen.filter(el => el !== id));  
      } else {
        this.$emit('setChosenList', [...this.chosen, id])
      }  
    },

    async getCitiesList(){
      this.loading=true;
      try {
        let res = await wayApi.get('/api/locations')
        console.log(res);
        if (res?.data.data){
          this.list = res?.data?.data;
        }
      } catch (err) {
        this.error = err;
      }
      this.loading = false;
    }
  },
  mounted(){
    this.getCitiesList();
  }
}
</script>
<style lang="scss">
.way__city-modal {
  display: flex; 
  flex-direction: column; 
  height: 100%;
  &__header{
    position: relative;
    margin: 18px 24px;
    display: flex;
    justify-content: center;
    &__arrow-div{
      position: absolute;
      left: 0;
    }
  }
  &__content{
    flex: 1 1 auto;
    min-height: 0;
    padding: 8px 24px;
    overflow-y: auto;
    position: relative;
    background-repeat: repeat;
    ul {
      padding-left: 1rem;
    }
    ol {
      padding-left: 1rem;
    }
    &__item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #DDE1E5;
      .item__title{
        flex-grow: 1;
        user-select: none;
      }
    }
  }
}
</style>